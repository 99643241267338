<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Region" :create="openCreateModal">
        <h1 class="title">Regions</h1>
      </page-header>

      <datatable
        ref="RegionsList"
        :fetch="$Api.Region.fetchRegions"
        :enableDownload="true"
        :entity="$EntitiesName.Region"
      >
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
        <div slot="direction" slot-scope="{ item }" title="Direction" :sortable="true" sortBy="direction.name">
          <span v-if="item.direction">{{ item.direction.name }}</span>
        </div>
      </datatable>
    </div>

    <modal ref="createRegionModal" @create="createRegion" title="Création d'une région">
      <div class="columns">
        <div class="column is-half">    
          <ValidationObserver ref="form">
          <text-field label="Nom" data-test="sel-region-name" v-model="region.name" :inline="false" :edit="true" rules="required"/>
          <many2one-field
            label="Direction"
            v-model="region.direction"
            :fetch="$Api.Direction.fetchDirections"
            reference="name"
            entity="user"
            :columns="directionColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
          ></many2one-field>
          </ValidationObserver>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'regions-list',
  data() {
    return {
      region :{},
      columns: {
        name: {
          title: 'Nom',
        },
        direction: {
          title: 'Direction',
        },

      },
      directionColumns: {
        name: 'Direction'
      }
    };
  },
    methods: {
    openCreateModal() {
      this.$refs.createRegionModal.open();
    },
    createRegion() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post('/regions', this.region).then((response) => {
          if (response.data.success) {
            this.region = {
              id: '',
              name: '',
              direction: '',
            };
            this.$refs.RegionsList.refresh();
            this.$refs.createRegionModal.close();
            this.$router.push({
              name: 'Region',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },

};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>